import React from 'react';
 

const EmptyState = ({ title, description, buttonLabel, onButtonClick }) => {
  return (
    <div className="empty-state">
        <div className="empty-icon"><i className="fa fa-file-text" aria-hidden="true"></i></div> 
      <p className="empty-title">{title}</p>
      <span className="empty-description">{description}</span>
      {buttonLabel && (
        <button className="add-resolution-btn" onClick={onButtonClick}>
          {buttonLabel}
        </button>
      )}
   

    </div>
  );
};

export default EmptyState;
