import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";

const initialState = {
    loading: true,
    data: [],
    dropitems: [],
    info: [],
    subData: [],
    subItemCurrentData: [],
    parent_config: [],
    success: false,
    error: '',
    nur:'ddd',
    attrIdentifier:[]
}

const url = `${BASE_URL}/FormBuilder/`

const token = localStorage.getItem("token")

const config = {

    headers: {
        Authorization: `Token ${token}`,
        // "content-type": "multipart/form-data",
        // 'Cache-Control': 'no-cache',
        // 'Pragma': 'no-cache',
        // 'Expires': '0',
    }
}


export const subItemConfigurationPost = createAsyncThunk(
    "create/subItemConfiguration", async (data) => {
        // let id = window.location.pathname.split("/");
        console.log('data', data)
        const curl = `${BASE_URL}/settings/${data.textId}/serviceSubItemConfiguration/`;
        return axios
            .post(curl, data.formData, config)
            .then((response) => response.data)
    }
)

export const fetchSubItemConfiguration = createAsyncThunk(
    "fetch/subItemConfigurationGet", async (data) => {
        // let id = window.location.pathname.split("/");
        const curl = `${BASE_URL}/settings/${data}/serviceSubItemConfiguration/`;
        return axios
            .get(curl, config)
            .then((response) => response.data)
    }
)


export const updateFormBuilder = createAsyncThunk(
    "update/formBuilder", async (formData) => {
        return axios
            .put(`${url}${formData.textId}`, formData, config)
            .then((response) => {
                response.data.localData = formData.localData;
                return response.data;
            })
    }
)


export const deleteFormBuilder = createAsyncThunk(
    "delete/formBuilder", async (formData) => {
        return axios
            .delete(`${url}${formData.textId}`, config)
            .then((response) => {
                // response.data.localData = formData.localData;
                return formData;
            })
    }
)


export const fetchFormBuilder = createAsyncThunk(
    'fetch/formbuilder', async () => {
        //  console.log('fetchFormBuilder in config: ',token)
        return axios
            .get(url, config)
            .then((response) => response.data)
    }
)

export const fetchConfiguration = createAsyncThunk(
    'fetch/configuration', async (textId) => {
        const curl = `${BASE_URL}/settings/${textId}/serviceItemConfiguration/`;
        return axios
            .get(curl, config)
            .then((response) => response.data)
    }
)

export const createItemConfiguration = createAsyncThunk(
    "create/formBuilder", async (data) => {
        let id = window.location.pathname.split("/"); 
        const curl = `${BASE_URL}/settings/${id[id.length - 2]}/serviceItemConfiguration/`;
        try {
            const response = await axios.post(curl, data, config);
            return response.data;
        } catch (error) {      
            console.log(error)
            return Promise.reject(error.response.status);  
        }
    //     return axios
    //         .post(cUrl, data, config)
    //         .then((response) => response.data)
    }
)

 

const formBuildSlice = createSlice({
    name: "formbuilder",
    initialState,
    reducers: {

        successConfirmation(state, action) {
            state.success = false;
            state.error = false;
        },
        updateState(state, action) {
            state.res = action.payload;
        },
        removeSuccessMessage(state, action) {
            state.success = action.payload;
        },
        appendItem(state, action) {
            state.data.push(action.payload);
            // state.data = action.payload;
            state.loading = false;

            // console.log("state:", state)
            // console.log("action:", action)
        },

        dropElement(state, action) {
            if (action.payload.rel === null) {
                state.dropitems.push(action.payload);
            } else {
                state.dropitems[action.payload.rel].controls.push(action.payload);
            }
        },

        updateValue(state, action) {
            // console.log('updateValue:', action.payload)
            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            let childIndex = action.payload[3];
            if (childIndex === null) {
                state.dropitems[rootIndex][name] = (value);
            } else {
                state.dropitems[rootIndex].controls[childIndex][name] = (value);
            }
        },
        updateLabel(state, action) {
            // console.log('updateValue:', action.payload)
            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            // let childIndex = action.payload[3];
            // if(childIndex===null){
            state.dropitems[rootIndex][name] = (value);
            state.dropitems[rootIndex]['fieldset'] = (value);
            // }else {
            //   state.dropitems[rootIndex].controls[childIndex][name]=(value);
            // }
        },
        updateMeasurement(state, action) {
            // console.log('updateValue:', action.payload)
            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            // let childIndex = action.payload[3];
            // if(childIndex===null){
            state.dropitems[rootIndex][name] = (value);
            // state.dropitems[rootIndex]['fieldset'] = (value);
            // }else {
            //   state.dropitems[rootIndex].controls[childIndex][name]=(value);
            // }
        },

        removeAttribute(state, action) {
            let item = action.payload[0];
            let subItem = action.payload[1];
            let textId = action.payload[2];
            console.log('item removeAttribute :', item)
            console.log(state.dropitems[item].controls[subItem])
            // console.log(state.dropitems)
            // if (subItem === null) {
            //     state.dropitems.splice(item, 1)
            //     state.subData.splice(item, 1)
            // } else {
                state.dropitems[item].controls.splice(subItem, 1)
                if(textId!=='') state.dropitems[item].deleteAttributeList.push(textId)
                // state.subData[item].controls.splice(subItem, 1)
            // }
        },

        removeAttributeSubItemElement(state, action) {
            let item = action.payload[0];
            let subItem = action.payload[1];
            // let subItem = action.payload[1];
            console.log('item, subItem', action.payload)
            console.log('item, subItem', item, subItem)
             
            // state.subItemCurrentData.splice(item, 1)
            //     state.subData.splice(item, 1)
            state.subItemCurrentData[item].controls.splice(subItem, 1)
            state.subData[item].controls.splice(subItem, 1)
             
        },

        removeSubItemElement(state, action) {
            // linked at subItem page
            let item = action.payload;
            // state.dropitems.splice(item, 1)
            state.subData.splice(item, 1)
            state.subItemCurrentData.splice(item, 1)
        },
        removeAttributeOptionsubItem(state, action) {
            let item = action.payload[0];
            let subItem = action.payload[1]; 
            let option = action.payload[2]; 
            console.log('removeAttributeOptionsubItem:', action.payload)
              
            state.subItemCurrentData[item].controls[subItem].options.splice(option, 1)
            state.subData[item].controls[subItem].options.splice(option, 1)
             
        },

        updateSubItemElementOption(state, action) {
            // console.log('ffffffffff:',action.payload)
            let data = action.payload;
            // let rootIndex = action.payload[0];
            // let name = action.payload[1];
            // let value = action.payload[2];
            // let childIndex = action.payload[3];
            // let optionIndex = action.payload[4];
            // if (childIndex === null) {
            //     state.dropitems[rootIndex][name][optionIndex] = value;
            // } else {
            //     state.dropitems[rootIndex].controls[childIndex][name][optionIndex] = value;
            // }

            // lavel:px, attr:cx, option:current,value:value
            state.subItemCurrentData[data.lavel].controls[data.attr].options[data.option].isActive=data.value;
            // state.subItemCurrentData[data.lavel].controls[childIndex][name][optionIndex] = value;
        },

        updateOptionRadioAndCheckebox(state, action) {
            let data = action.payload;

            // let rootIndex = action.payload[0];
            // let name = action.payload[1];
            // let value = action.payload[2];
            // let childIndex = action.payload[3];
            // let optionIndex = action.payload[4];

            // state.dropitems[rootIndex].controls[childIndex][name][optionIndex].title = value;
            state.dropitems[data.lavel].controls[data.attr].options[data.option].title = data.value;

            // state.dropitems[data.lavel].controls[data.attr].options[data.option].isActive=data.value;
        },

        updateOption(state, action) {

            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            let childIndex = action.payload[3];
            let optionIndex = action.payload[4];
            if (childIndex === null) {
                state.dropitems[rootIndex][name][optionIndex] = value;
            } else {
                state.dropitems[rootIndex].controls[childIndex][name][optionIndex] = value;
            }
        },

        removeOption(state, action) {
            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            let childIndex = action.payload[3];

            if (childIndex === null) {
                state.dropitems[rootIndex][name].splice(value, 1);
                // state.dropitems[action.payload[0]]['options'].splice(optIndex,1);
            } else {
                // state.dropitems[rootIndex].controls[childIndex][name]=(value);
                state.dropitems[rootIndex].controls[childIndex][name].splice(value, 1);
            }
        },
        updateGroup(state, action) {
            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            let childIndex = action.payload[3];
            let callFrom = action.payload[4];
            console.log(action)
            if (childIndex === 'labelField') {
                console.log('got the point!!!!', name, action.payload[1], 'ungroupService')
                state.dropitems[rootIndex][name] = value;
                if (callFrom) {
                    state.subData[rootIndex][name] = value;
                }
            } else {
                state.dropitems[rootIndex].controls[childIndex][name] = value;
                if (callFrom) {
                    state.subData[rootIndex].controls[childIndex][name] = value;
                }
            }
        },

        updateSubItemPriceConfiguration(state, action) {
            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            let childIndex = action.payload[3];
            // let callFrom = action.payload[4];
            
            state.subData[rootIndex].controls[childIndex][name] = value;
            state.subItemCurrentData[rootIndex].controls[childIndex][name] = value;
        },

        setConfigurationData(state, action) {
            // state.info = action.payload;
            // console.log('---------',action.payload);
            if (action.payload !== false) {
                state.dropitems = action.payload;
                // console.log('----set data-----',action.payload);
            }
        },
        resetConfiguration(state, action) {
            console.log(action)
            state.subItemCurrentData = action.payload;
            state.subData = action.payload;
        },


        updateSubItemElement(state, action) {
            console.log('updateValue:', action.payload)
            let rootIndex = action.payload[0];
            let name = action.payload[1];
            let value = action.payload[2];
            let childIndex = action.payload[3];
            // if(childIndex===null){
            // state.dropitems[rootIndex][name]=(value);
            // state.dropitems[rootIndex]['fieldset']=(value);
            // }else {
            // state.subData[rootIndex].controls[childIndex][name] = (value);
            state.subItemCurrentData[rootIndex].controls[childIndex][name] = (value);
            // }
          
        }, 
        attributeRankUpdate(state, action) {
            let data = action.payload;
            console.log('ranks::', data)
            state.dropitems[data.lavel].rank=data.rank;
            // state.dropitems[data.lavel].controls[data.attr].rank=data.rank;
         },
        attributeSwap(state, action) {
            let data = action.payload;
            console.log('ranks::', data)
            state.dropitems[data.lavel].controls = data.controlData;
         }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFormBuilder.pending, (state) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(fetchFormBuilder.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = "";
            // console.log('kkkkkkk')
        });
        builder.addCase(fetchFormBuilder.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error;
        });

        builder.addCase(fetchConfiguration.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.dropitems = []
            state.subData = []
            // console.log('pending');

        });
        builder.addCase(fetchConfiguration.fulfilled, (state, action) => {
            // console.log('configurationJson***',action.payload.configurationJson)
            state.loading = false;
            console.log('fulfilled', action.payload.configuration.configurationJson);
            state.dropitems = action.payload.configuration.configurationJson;
            state.subData = null;
            state.info = action.payload;
            state.attrIdentifier = action.payload.attribute_identifier;

            if (action.payload.configuration.configurationJson != null) {
                state.dropitems = JSON.parse(action.payload.configuration.configurationJson);
                state.subData = JSON.parse(action.payload.configuration.configurationJson);
                console.log('fulfilled', JSON.parse(action.payload.configuration.configurationJson));
            } else {
                state.dropitems = [];
                state.subData = []; 
            }
            state.error = "";
          
        });
        builder.addCase(fetchConfiguration.rejected, (state, action) => {
            state.loading = false;
            state.info = [];
            state.error = action.error;
        });

        builder.addCase(fetchSubItemConfiguration.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.dropitems = [];
            state.info = [];
            // state.subData =[];
        });
        builder.addCase(fetchSubItemConfiguration.fulfilled, (state, action) => {
            // console.log('configurationJson***',action.payload.configurationJson)
            state.loading = false;
            // state.info = action.payload;
             console.log('if  slice', action.payload)
            if (action.payload.own_config != null) {
                state.parent_config = action.payload.parent_config;
                state.subItemCurrentData = action.payload.own_config;
                state.subData = action.payload.own_config;
                // console.log('if  slice', action.payload)
            } else {
                state.subItemCurrentData = action.payload.parent_config;
                state.subData = action.payload.parent_config;
                state.parent_config = action.payload.parent_config;
               
            }
            // state.isBundleItem = action.payload.isBundleItem
             console.log( action.payload.parent_config)
            state.error = "";
            // console.log(action.payload, JSON.parse(action.payload.configurationJson))
            // console.log(state.config)
        });
        builder.addCase(fetchSubItemConfiguration.rejected, (state, action) => {
            state.loading = false;
            state.info = [];
            state.error = action.error;
        });

        builder.addCase(createItemConfiguration.pending, (state, action) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(createItemConfiguration.fulfilled, (state, action) => {
            state.loading = false;
            state.error = "";
            state.data.push(action.payload);
            state.success = true;

        });

        builder.addCase(createItemConfiguration.rejected, (state, action) => {
            state.loading = false;
            console.log('action.error ',action.error)
            state.error = action.error;
            state.success = false;
            // console.log('response in slice', action)
        });
        
        builder.addCase(subItemConfigurationPost.pending, (state, action) => {
            state.loading = true;
            state.success = false;
        });

        builder.addCase(subItemConfigurationPost.fulfilled, (state, action) => {
            state.loading = false;
            // state.data = action.payload;
            state.error = "";
            // state.data.push(action.payload);
            state.success = true;
            state.subItemCurrentData=[]
            state.subData=[]
        });

        builder.addCase(subItemConfigurationPost.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success = false;
        });

        builder.addCase(updateFormBuilder.fulfilled, (state, action) => {
            state.loading = false;
            state.data[action.payload.localData] = action.payload;
            state.error = "";
            // console.log(action.payload);
        });

        builder.addCase(updateFormBuilder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            //  console.log(action.error);
        });

        builder.addCase(deleteFormBuilder.fulfilled, (state, action) => {
            state.loading = false;
            state.data.splice(action.payload.localData, 1);
            state.error = "";
            // console.log("🚀 ~ file: FormBuilder.js:110 ~ builder.addCase ~ action:", action)
        });

        builder.addCase(deleteFormBuilder.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            //  console.log("🚀 ~ file: FormBuilder.js:120 ~ builder.addCase ~ action:", action.error)
        });

    },
});


export const {
    updateState,
    removeSuccessMessage,
    removeAttributeSubItemElement,
    removeAttributeOptionsubItem,
    updateSubItemElementOption,
    appendItem,
    dropElement,
    updateValue,
    removeAttribute,
    updateOption,
    removeOption,
    updateLabel,
    updateGroup,
    updateOptionRadioAndCheckebox,
    updateMeasurement,
    setConfigurationData,
    updateSubItemElement,
    removeSubItemElement,
    updateConfigurationPrice,
    resetConfiguration,
    successConfirmation,
    attributeRankUpdate,
    updateSubItemPriceConfiguration,
    attributeSwap

} = formBuildSlice.actions;
export default formBuildSlice.reducer
