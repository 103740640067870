import { BrowserRouter, Routes, Route, useNavigate, } from "react-router-dom";
import Login from "./pages/Login";

import Registration from "./pages/registration/Registration";
import AdminHome from "./pages/dashboard/AdminHome";
import AdminProfile from "./pages/profile/AdminProfile";
import Logout from "./pages/Logout";
import NotFound from "./components/NotFound";
import ServiceList from "./pages/service/ServiceList";
import { useEffect } from "react";
import Setting from "./pages/setting/Setting";
import AreaList from './pages/area/AreaList';
// import AddAreaPage from './pages/area/AddAreaPage';
// import CategoryList from "./pages/category/CategoryList";

import AddCategoryPage from './pages/category/AddCategoryPage'

import CityList from "./pages/city/CityList";
import CityZipList from "./pages/cityZip/CityZipList";
import TeamMemberList from "./pages/teamMember/TeamMemberList";
import PendingTeamMemberList from "./pages/teamMember/PendingTeamMemberList";
import PreApprovedTeamMemberList from "./pages/teamMember/preApprovedTeamMemberList";
import ApprovedTeamMemberList from "./pages/teamMember/ApprovedTeamMemberList";
import '@fortawesome/fontawesome-free/css/all.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
// import "./assets/css/modal.css";
import Module from "./pages/admin/Module";
import Role from "./pages/admin/Role";
import PermissionList from "./pages/admin/Permission";


import ServiceAddForm from "./pages/service/ServiceAddForm";
import CategoryAddForm from "./pages/category/CategoryAddForm";


import FieldsetBuilderList from "./pages/fieldsetBuilder/FieldsetBuilderList";
import RndServiceList from "./pages/service/rnd__ServiceList";
import OrderListPage from "./pages/order/OrderListPage";
import FranchiseMemberList from "./pages/franchise/FranchiseMemberList";
import CustomerListPage from "./pages/customer/CustomerListPage";

// import AttributeList from "./pages/setting/AttributeList";
import ItemListConfigPage from "./pages/setting/ItemListConfigPage";
import NotificationTemplate from "./pages/notificationSetting/NotificationTemplate";
import JointNotificationToStage from "./pages/notificationSetting/JointNotificationToStage";
import NotificationCategories from "./pages/notificationSetting/NotificationCategories";
import FAQTemplate from "./pages/faqSetting/FAQTemplate";
import WorkerDesignation from "./pages/adminSetting/WorkerDesignation";
import WorkerRole from "./pages/adminSetting/WorkerRole";
import WorkerModule from "./pages/adminSetting/WorkerModule";
import WorkerDesignationRole from "./pages/adminSetting/WorkerDesignationRole";
import WorkerRolePermission from "./pages/adminSetting/WorkerRolePermission";
import IdentityListPage from "./pages/serviceIdentity/IdentityListPage";
// import AttrDetails from "./pages/setting/DetailsPage";
// import AttributeGroupList from "./pages/setting/AttributeGroupList";
// import ServiceItemAddForm from "./pages/setting/ServiceItemAddForm";
import ServiceItemList from "./pages/setting/ServiceItemList";
import ServiceSubItemListPage from "./pages/setting/ServiceSubItemListPage";
// import ServiceSubItemList2 from "./pages/setting/ServiceSubItemList2";
import AddNewPage from "./pages/setting/AddNewPage";
// import AddSubItemPage from "./pages/setting/AddSubItemPage";
import ServiceItemConfiguration from "./pages/setting/ServiceItemConfiguration";
import ProfilePage from "./pages/teamMember/ProfilePage";
// import Requests from "./pages/requests";
import ServiceRequestList from "./pages/ServiceRequest/ServiceRequestList";
import CategoryRequestList from "./pages/ServiceRequest/CategoryRequestList";
// import PriceRequestList from "./pages/ServiceRequest/PriceRequestList";
import AreaRequestList from "./pages/ServiceRequest/AreaRequestList";
import SubItemConfiguration from "./pages/setting/SubItemConfiguration";
import OrderDetailsPage from "./pages/teamMember/OrderDetailsPage";
import ProfileDetails from "./pages/teamMember/ProfileDetails";
import EditItemPage from "./pages/setting/EditItemPage";
import EditCategoryPage from "./pages/category/EditCategoryPage";
import EditServicePage from "./pages/service/EditServicePage";
import ViewServicePage from "./pages/service/VeiwServicePage";
import Notifications from "./pages/notifications/Notifications";

// import AttributeGroup from "./pages/attributeGroup/AttributeGroup";
import TestingZone from "./component/area/TestingZone";
import ShimmerAnimation from "./pages/shimmerAnimation/ShimmerAnimation";
import ServiceListByItemPage from "./pages/area/ServiceListByItemPage";
import PriceManagement from "./pages/ServiceRequest/PriceManagement";
import AreaConfiguration from "./pages/area/AreaConfiguration";
import ServiceViewWithPriceList from "./pages/teamMember/ServiceViewWithPriceList";
import TestingFilterComponent from "./component/testing-filter/TestingFilterComponent";
import TipsAndRewardsPage from "./pages/tipsRewards/TipsAndRewardsPage";
import RatingsListPage from "./pages/ratings/RatingsListPage";
import TestingFileDownload from "./pages/testing/TestingFileDownload";
import UserProfilePage from "./pages/customer/UserProfilePage";
import TestingFCMNotification from "./pages/testing/TestingFCMNotification";
import DisputeAndClaim from "./pages/disputeAndClaim/InvoiceListPage";

import PageSetting from './pages/metaSetting/PageSetting';

import Websocket from './pages/testing/Websocket';
import DisputeSetting from "./pages/disputeAndClaim/DisputeSettingPage";


// function TipsandRewardsPage() {
//   return null;
// }

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<RedirectBasedOnToken />} />
        {/* <Route path="/login" element={<RedirectBasedOnToken />} /> */}
   
        <Route path="/login" element={<Login />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/register" element={<Registration />}></Route>
        <Route path="/dashboard" element={<AdminHome />}></Route>
        <Route path="/profile" element={<AdminProfile />}></Route>
        {/*<Route path="/" element={<AdminHome />}></Route>*/}

        <Route path="/service-items" element={<ServiceItemList/>}></Route>
        <Route path="/service-items/:item" element={<ServiceSubItemListPage />}></Route>
        
        <Route path="/service-items/add-item" element={<AddNewPage />}></Route>
        <Route path="/service-items/edit-item/:item" element={<EditItemPage />}></Route>
        <Route path="/service-items/:item/configuration" element={<ServiceItemConfiguration />}></Route>
        <Route path="/service-items/:item/configuration-sub" element={<SubItemConfiguration />}></Route>

        <Route path="/service-area" element={<AreaList />}></Route>
        <Route path="/service-area/:item" element={<ServiceListByItemPage />}></Route>
        <Route path="/service-area/:item/zone/:id" element={<AreaConfiguration />}></Route>

        <Route path="/service" element={<ServiceList />}></Route>
        <Route path="/service/:item" element={<AddCategoryPage />}></Route>
        <Route path="/service/:item/:categoryId" element={<AddCategoryPage />}></Route>

        <Route path="/service/add-service/:item/:category" element={<ServiceAddForm />}></Route>
        <Route path="/service/:item/:serviceId/view" element={<ViewServicePage />}></Route>
        <Route path="/service/edit-service/:item/:service" element={<EditServicePage />}></Route>
        <Route path="/service/add-category/:item/:category" element={<CategoryAddForm />}></Route>
        <Route path="/service/edit-category/:item/:category" element={<EditCategoryPage />}></Route>

        {/*<Route path="/service/:item/:subItem/add" element={<ServiceAddForm />}></Route>*/}
        {/*<Route path="/service/:item/:subItem/:subLen1" element={<AddCategoryPage />}></Route>*/}
        {/*<Route path="/service/:item/:subItem/:subLen1/:subLen2" element={<AddCategoryPage />}></Route>*/}

        <Route path="/cities" element={<CityList />}></Route>
        <Route path="/cityzip" element={<CityZipList />}></Route>
        <Route path="/servicebuilder" element={<FieldsetBuilderList />}></Route>
 
        <Route path="/order" element={<OrderListPage />}></Route>
        <Route path="/order/:orderTextId" element={<OrderDetailsPage />}></Route>
        <Route path="/admin" element={<Module />}></Route>
        <Route path="/admin/modules" element={<Module />}></Route>
        <Route path="/admin/roles" element={<Role />}></Route>
        <Route path="/admin/permissions" element={<PermissionList />}></Route>

        <Route path="/provider" element={<TeamMemberList />}></Route>
        <Route path="/provider/provider-list" element={<TeamMemberList />}></Route>
        <Route path="/provider/provider-list/:item" element={<ProfilePage />}></Route>
        <Route path="/provider/provider-list/:item/:orderTextId" element={<OrderDetailsPage />}></Route>
        <Route path="/provider/:item/:serviceId" element={<ServiceViewWithPriceList />}></Route>
        <Route path="/provider/test/:item" element={<ProfileDetails />}></Route>
        <Route path="/provider/pending" element={<PendingTeamMemberList />}></Route>
        <Route path="/provider/pre-approve" element={<PreApprovedTeamMemberList />}></Route>
        <Route path="/provider/approved" element={<ApprovedTeamMemberList />}></Route>
        <Route path="/franchise" element={<FranchiseMemberList />} ></Route>
        <Route path="/provider/category" element={<CategoryRequestList />} ></Route>
        <Route path="/provider/service" element={<ServiceRequestList />} ></Route>
        <Route path="/provider/area" element={<AreaRequestList />} ></Route>
        {/*<Route path="/provider/price" element={<PriceRequestList />} ></Route>*/}
        <Route path="/provider/manage-price" element={<PriceManagement />} ></Route>

        
        <Route path="/EndUser" element={<CustomerListPage />} ></Route>
        <Route path="/EndUser/:user" element={<UserProfilePage />} ></Route>
        <Route path="/EndUser/:user/:orderTextId" element={<OrderDetailsPage />} ></Route>


        
        <Route path="/notifications" element={<Notifications />}></Route>
        
        <Route path="/dispute" element={<DisputeAndClaim />}></Route>
        <Route path="/dispute/invoice" element={<DisputeAndClaim />}></Route>
        <Route path="/dispute/setting" element={<DisputeSetting />}></Route>

        <Route path="/setting" element={<Setting />}></Route>
        <Route path="/setting/admin-notice" element={<NotificationTemplate />}></Route>
        <Route path="/setting/category" element={<NotificationCategories />}></Route>
        <Route path="/setting/stage" element={<JointNotificationToStage />}></Route>
        <Route path="/setting/item-config" element={<ItemListConfigPage />}></Route>
        <Route path="/setting/faq" element={<FAQTemplate />}></Route>

        <Route path="/setting/worker-designation" element={<WorkerDesignation />}></Route>
        <Route path="/setting/worker-role" element={<WorkerRole />}></Route>
        <Route path="/setting/worker-module" element={<WorkerModule />}></Route>
        <Route path="/setting/worker-designation-role" element={<WorkerDesignationRole />}></Route>
        <Route path="/setting/worker-role-permission" element={<WorkerRolePermission />}></Route>
        <Route path="/setting/meta" element={<PageSetting />}></Route>

        <Route path="/setting/identity" element={<IdentityListPage />}></Route>
        <Route path="/setting/stage" element={<JointNotificationToStage />}></Route>
        {/*<Route path="/setting/attribute" element={<AttributeList />}></Route>*/}
        {/*<Route path="/setting/attribute/:id" element={<AttrDetails />}></Route>*/}
        {/*<Route path="/setting/attribute-group" element={<AttributeGroupList />}></Route>*/}

        <Route path="/tips-rewards" element={<TipsAndRewardsPage />} ></Route>
        <Route path="/ratings" element={<RatingsListPage />} ></Route>

        <Route path="/rnd" element={<RndServiceList />} ></Route>
        <Route path='*' element={<NotFound />}/>

        {/*<Route path="/setting" element={<Setting />}>*/}
        {/*  <Route index element={<AttributeList />} />*/}
        {/*  <Route path=":id" element={<AttrDetails />} />*/}
        {/*  <Route path="attribute-group" element={<AttributeGroupList />} />*/}
        {/*</Route>*/}
        {/* <Route path="/setting/attribute-group/:id" element={<AttributeGroup />}></Route> */}

        {/*<Route path="service-area" element={<AreaList />}>*/}
        {/*  <Route path=":item/*" element={<AddAreaPage />} />*/}
        {/*</Route>*/}
        {/* <Route path="service-area/:item/:item/:item/:item" element={<AddAreaPage />}></Route> */}
        {/*<Route path="service-items/add-item/add-item" element={<AddSubItemPage />}></Route>*/}

        {/*<Route path="service-items/:item" element={<ServiceSubItemListPage />}></Route>*/}
        {/*<Route path="service-items/:item/:subItem" element={<ServiceSubItemList2 />}></Route>*/}
        {/*<Route path="service-items/:item/:subItem/:subLen1" element={<ServiceSubItemList2 />}></Route>*/}
        {/*<Route path="service-items/:item/:subItem/:subLen1/:subLen2" element={<ServiceSubItemList2 />}></Route>*/}

        {/*<Route path="service-items" element={<ServiceItemList />}>*/}
        {/*  /!*<Route path=":/add" element={<AddNewPage />} />*!/*/}
        {/*  <Route path=":item/*" element={<ServiceSubItemList2 />} />*/}
        {/*</Route>*/}

        {/*<Route path="/service/customize" element={<ServiceCustomizeFormList />}></Route>*/}
        {/*<Route path="/service/customize/:id" element={<ControlList />}></Route>*/}
        {/*<Route path="/service/customize/:id/:id" element={<ControlListL1 />}></Route>*/}
        {/*<Route path="/service/customize/:id/:id/:id" element={<ControlListL2 />}></Route>*/}
        {/*<Route path="/service/customize/:id/:id/:id/:id" element={<ControlListL3 />}></Route>*/}
        {/*<Route path="/service/customize/:id/:id/:id/:id/:id" element={<ControlListL4 />}></Route>*/}
        {/*<Route path="/service/customize/:id/:id/:id/:id/:id/:id" element={<ControlListL5 />}></Route>*/}
        {/*<Route path="/service/customize/:id/:id/:id/:id/:id/:id/:id" element={<ControlListL6 />}></Route>*/}
        {/*<Route path="/service/customization" element={<ServiceCustomization />}></Route>*/}
        
        <Route path="/testing-zone" element={<TestingZone />}></Route>
        <Route path="/testing" element={<TestingFCMNotification />}></Route>
        <Route path="/testing/websocket" element={<Websocket />}></Route>
        <Route path="/testing-ShimmerAnimation" element={<ShimmerAnimation />}></Route>
        <Route path="/testing-filter" element={<TestingFilterComponent />}></Route>
        <Route path="/file-download" element={<TestingFileDownload />}></Route>

      </Routes>
    </BrowserRouter>
  );
}



export default App;

function RedirectBasedOnToken() {
  const navigate = useNavigate();
  useEffect(() => {
 
    const token = localStorage.getItem("token");
    if (token ==='undefined' || token === null) {navigate("/login")} else { navigate("/dashboard")}
  }, [navigate]);

  return null; // or any loading component if needed
}
