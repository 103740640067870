import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    unauthorized: true,
    loading: false,
    data: [],
    notice: [],
    stages: [],
    categories: [],
    variable_array: [],
    notification_list:[],
    count: 0,
    error: ''
}

const url = `${BASE_URL}/service`

const token = localStorage.getItem("token")
const textId = localStorage.getItem("textId")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


 

export const fetchNotificationCategoriyList = createAsyncThunk(
    'fetch/fetchNotificationCategories', async () => {
        return axios
        .get(`${url}/NotificationCategories/`, config)
        .then((response) => response.data)
    }
)

export const createNotificationCategory = createAsyncThunk(
    'fetch/createCategory_n', async (data) => {
        return axios
        .post(`${url}/NotificationCategories/`,data ,config)
        .then((response) => response.data)
    }
)

export const updateNotificationCategory = createAsyncThunk(
    'fetch/updateCategory_n', async (data) => {
        return axios
        .put(`${url}/NotificationCategories/`,data ,config)
        .then((response) => response.data)
    }
)

export const fetchNotificationTemplateData = createAsyncThunk(
    'fetch/GetTemplate', async () => {
        return axios
        .get(`${url}/GetAllNotificationTemplate/`, config)
        .then((response) => response.data)
    }
)

export const deleteNotificationTemplate= createAsyncThunk(
  "delete/deleteNotificationTemplate", async (data) => {
    return axios 
    .get(`${url}/NotificationMessageDelete/${data.textId}/`, config)
    .then((response) => response.data);
  }
)

export const updateNotificationSettingColumns = createAsyncThunk(
    'fetch/updateNotificationSetting', async (data) => {
        return axios
        .put(`${url}/GetAllNotificationTemplate/`, data, config)
        .then((response) => response.data)
    }
)

export const fetchNotificationBellCount = createAsyncThunk(
  'fetch/NotificationBellCount', async () => {
    try {
      const response = await axios.get(`${url}/${textId}/notificationBellCount/`, config);
      return response.data;
    } catch (error) {      
      return Promise.reject(error.response.status);  
    }
  }
);


export const markAllRead = createAsyncThunk(
    'fetch/MarkAllRead', async () => {
        return axios
        .get(`${url}/UpdateAllNotificationStatus/`, config)
        .then((response) => response.data)
    }
)

// export const addNotificationTemplatePost = createAsyncThunk(
//     "create/SaveNotificationTemplate", async (data) => { 
//         const cUrl = `${url}/SaveNotificationTemplate/`;
//         return axios
//             .post(cUrl, data, config)
//             .then((response) => console.log(response) )
//     }
// )

export const addNotificationTemplatePost = createAsyncThunk(
  "create/SaveNotificationTemplate",
  async (data, { rejectWithValue }) => {
    const cUrl = `${url}/SaveNotificationTemplate/`;

    try {
      const response = await axios.post(cUrl, data, config);
      return response.data; // Return data on success
    } catch (error) {
 
      if (error.response && error.response.status === 409) {
        // Handle specific 409 status code
        return rejectWithValue({
          status: error.response.status, 
          message: error.response.data.error
        });
      }
      // Handle other errors
      return rejectWithValue({
        status: error.response?.status || 500,
        message: error.response.data.error,
        // message: error.message || 'An unknown error occurred',
      });
    }
  }
);




export const updateNotificationTemplatePut = createAsyncThunk(
    "update/updateNotificationTemplatePut", async (data) => {
        const cUrl = `${url}/UpdateNotificationTemplate/`;
        return axios
            .put(cUrl, data, config)
            .then((response) => response.data)
    }
)

export const readNotification = createAsyncThunk(
    "update/readNotification", async (data) => {
       
        const cUrl = `${url}/UpdateNotificationStatus/`;
        return axios
            .put(cUrl, data, config)
            .then((response) => response.data)
    }
)

// export const updateCity = createAsyncThunk(
//   "update/city", async (formData) => {
//     return axios
//     .put(`${url}${formData.textId}`, formData, config)
//     .then((response) =>{ 
//     response.data.localData = formData.localData;
//     console.log("🚀 ~ file: CitySlice.js:59 ~ .then ~ formData:", formData)
//     return response.data; 
//      } 
//   )
//   }
// )

export const fetchNotificationData = createAsyncThunk(
    'fetch/NotificationData', async () => {
        return axios
        .get(`${url}/${textId}/GetAllNotification/`, config)
        .then((response) => response.data)
    }
)

export const fetchNotificationStage = createAsyncThunk(
    'fetch/NotificationStageList', async () => {
        return axios
        .get(`${url}/NotificationStage/`, config)
        .then((response) => response.data)
    }
)

export const updateNotificationStage = createAsyncThunk(
    'fetch/updateStage', async (data) => {
      console.log(data)
        return axios
        .put(`${url}/NotificationStage/`, data, config)
        .then((response) => {
          // response.data.data=data;
          console.log('updateNotificationStage:',response)
          return response.data;
        })
    }
)

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        updateBellCount(state, action) {
            state.count = state.count+1;
            console.log('bell count incrememt!!')
        },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchNotificationData.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationData.fulfilled, (state, action) => {
        state.loading = false;
        state.notice = action.payload.results;
        state.count = action.payload.notificationCount;
        state.error = "";
      });
      builder.addCase(fetchNotificationData.rejected, (state, action) => {
        state.loading = false;
        state.notice = [];
        state.error = action.error;
      });

      builder.addCase(fetchNotificationStage.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationStage.fulfilled, (state, action) => {
        state.loading = false;
        state.stages = action.payload.results;
        state.notification_list = action.payload.notification_list;

        // state.count = action.payload.notificationCount;
        state.error = "";
        console.log('results::', action.payload.results)
      });
      builder.addCase(fetchNotificationStage.rejected, (state, action) => {
        state.loading = false;
        state.notice = [];
        state.error = action.error;
      });

      builder.addCase(updateNotificationStage.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateNotificationStage.fulfilled, (state, action) => {
        state.loading = false;
        state.stages[action.payload.updatedData.localData] = action.payload.updatedData;
        console.log(action.payload)
  
        state.error = "";
        console.log('results::', action.payload.results)
      });
      builder.addCase(updateNotificationStage.rejected, (state, action) => {
        state.loading = false;
        state.notice = [];
        state.error = action.error;
      });

      builder.addCase(fetchNotificationTemplateData.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationTemplateData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.results;
        state.categories = action.payload.category_List;
        state.variable_array = action.payload.variable_array;
        state.error = "";
      });
      builder.addCase(fetchNotificationTemplateData.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error;
      });
      builder.addCase(markAllRead.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(markAllRead.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.results){
        state.notice = action.payload.results;}
        state.error = "";
        console.log(action.payload.results)
      });
      builder.addCase(markAllRead.rejected, (state, action) => {
        state.loading = false;
        state.notice = [];
        state.error = action.error;
      });

      builder.addCase(fetchNotificationCategoriyList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationCategoriyList.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload.results){
          state.categories = action.payload.results;
        }
        state.error = "";
      });
      builder.addCase(fetchNotificationCategoriyList.rejected, (state, action) => {
        state.loading = false;
        state.categories = [];
        state.error = action.error;
      });
       
      builder.addCase(createNotificationCategory.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createNotificationCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.categories.push(action.payload.item);
        state.error = "";
      });
      builder.addCase(createNotificationCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(updateNotificationCategory.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateNotificationCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.categories[action.payload.data.localData] = action.payload.data;
        state.error = "";
      });
      builder.addCase(updateNotificationCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(updateNotificationTemplatePut.pending, (state) => {
        state.loading = true;
        
      });
      builder.addCase(updateNotificationTemplatePut.fulfilled, (state, action) => {
        state.loading = false;
        state.data[action.payload.data.localData] = action.payload.data;
        // state.cities.push(action.payload);
        state.error = "";
        console.log('ddddd:', action.payload.data.localData)
        console.log('ddddd:', action.payload.data)
      });
      builder.addCase(updateNotificationTemplatePut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
   
      });

      builder.addCase(addNotificationTemplatePost.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(addNotificationTemplatePost.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.results)
         
       
        state.data.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(addNotificationTemplatePost.rejected, (state, action) => {

        state.loading = false;
        state.error = action.payload?.message || 'An error occurred';  
        if (action.payload?.status === 409) {
          console.error('Conflict Error:', action.payload.message);  
        }
      });
      builder.addCase(fetchNotificationBellCount.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchNotificationBellCount.fulfilled, (state, action) => {
        state.loading = false;
        state.unauthorized= true;
        state.count =action.payload.results;
        state.error = "";
      });
      builder.addCase(fetchNotificationBellCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        if(action.error.message){
          state.unauthorized= false
          window.location.assign('/logout');
        }
      });

      // builder.addCase(readNotification.pending, (state) => {
      //   state.loading = true;
      // });
      // builder.addCase(readNotification.fulfilled, (state, action) => {
      //   state.loading = false;
         
      //   console.log('ooooooooo',action.payload)
      //   state.notice[action.payload.data.index]='Y';
      //   state.error = "";
      // });
      // builder.addCase(readNotification.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.error;
      // });
    },
  });
  
export const { updateBellCount } = notificationSlice.actions;

export default notificationSlice.reducer

 
