import React, { useState} from "react";
// import {Form, Modal, Button, Image} from "react-bootstrap";
// import * as moment from 'moment';
// import CityForm from "./CityForm";
// import ManageZipForm from "./ManageZipForm";
// import {useDispatch, useSelector} from "react-redux";
// import { citySchema } from "../../schemas/city";
// import { userSchema } from "./schemas/city";

// import {fetchOrder, fetchOrderTracking} from "../../reducers/OrderSlice";
// import { monthNames } from "../../utility/DateFormate";
import CustomDataTable from "../../components/CustomDataTable";
// import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
// 
import {filterDataTable2Col} from "../../utility/FilterDataTable";
import ExportFile from "../../component/utility-component/ExportFile";
// import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
// import { Calendar } from 'react-date-range';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
// import DatePicker from "react-datepicker";
// import { DateRangePicker } from 'react-date-range';
// import NotDataFound from "../../components/NotDataFound";

const ClaimListComponent = ({data}) => {
    const [query, setQuery] = useState('');
 
    let tableData2 = data.filter(item => item['endUserOrderTextId']?.toLowerCase().includes(query.toLowerCase())) 
    let tableData1 = data.filter(item => item['serviceTitle']?.toLowerCase().includes(query.toLowerCase()))
 
    // const uniqueData = data
    const uniqueData = filterDataTable2Col('disputeClaimTextId',tableData1, tableData2);
    
    const columns = [
        { name: "Dispute Claim Id",   sortable: true, selector: (row) =>  row.disputeClaimTextId },
        { name: "Order Id",   sortable: true, selector: (row) =>  row.endUserOrderTextId },
        { name: "Service Name",   sortable: true, selector: (row) =>  row.serviceTitle },
        { name: "User Name",   sortable: true, selector: (row) => <div className="info"><strong className='name-title'>{row.userData.userName}</strong> <br/><span>{row.userData.userEmail} </span><br/><span className="type">{row.userData.userType} </span></div>},
        { name: "Claim Date",   sortable: true, selector: (row) =>  row.created },
        { name: "Status", minWidth: '120px', sortable: true, 
            selector: (row) => <span className={`status ${row.disputeClaimStatus}`}> {row.disputeClaimStatus}</span>
        },
        { name: "Details", cell: (row) => <Link to={`./${row.textId}`} className="btn btn-default"> View Details</Link> },
        
    ]

 
 
 

    return  (<>
         

 
        <div className='data-table-wrapper order-table'>
            <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                    <input name="search" placeholder={'Search by order ID or service Title...'}
                            type="text"
                            className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                            value={query ? query : ''}
                            onChange={(e) => {
                                setQuery(e.target.value)
                            }}/>
                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                    <div
                        className="export-btn absolute right-0 top">
                        {/* <ExportFile data={uniqueData} fileName={'Order-Table-data'}/> */}
                    </div>
                </div>

            </div>
            <CustomDataTable data={uniqueData} columns={columns}/>
        </div>
        
     
 
</>)
}
export default ClaimListComponent;
