import React, {useEffect, useState} from "react";
import { NavLink, Link } from "react-router-dom";
import { ReactComponent as Down } from '../assets/images/down.svg';
import { ReactComponent as User } from '../assets/images/user.svg';
import User2 from '../assets/images/user.png';
import {useDispatch, useSelector} from "react-redux";
import NotificationModal from "../component/notifications/NotificationModal";
 
const CommonHeader = ({ user, title }) =>{
  const [showDropDown, setShowDropDown] = useState(false);
  const [show, setShow] = useState(false);
  const {count} = useSelector((state) => state.notification);

  const handeleShow = () => !show?setShow(true):setShow(false); 
  const handeleShowDropDown = () => !showDropDown?setShowDropDown(true):setShowDropDown(false); 
 
  return (
    <>
    
      <div className="profile">
        <span className='notification-bell text-2xl mx-4 cursor-pointer text-[#999]' 
          onClick={()=>{
            return handeleShow()
            }}><i className="fa fa-bell-o" aria-hidden="true"></i>
            {count>0 && <span className="notification-counter"> {count} </span>}
          </span>
          
        <div className="user" onClick={()=>handeleShowDropDown()}>
          {/*<User className='user-icon'/>*/}
          {/* <span className="name">User Name</span>  */}
          
          <img className='user-icon' src={User2} alt="User"/>
          <span className="name">{localStorage.getItem('userName')}</span> 
          {/* <a href='/logout' className="name">Logout</a> */}
          <Down className='down-icon'/>
        </div>
        {show && <NotificationModal close={handeleShow} count={count}/>}
       {showDropDown && <>
       <div className="offset-layer" onClick={()=>handeleShowDropDown()}></div>
      <div className="drop-down">
        <Link className="item" to='/profile'>
          <img className='icon' src={User2} alt="User"/> 
          <span className="text">Profile</span>
        </Link>
        <Link className="item" to='/setting'>
          <i className="fa fa-cog icon" aria-hidden="true"></i>
          <span className="text">Setting</span>
        </Link>
        <Link className="item" to='/logout'>
        <i className="fa fa-sign-out icon" aria-hidden="true"></i> 
          <span className="text">Logout</span>
        </Link>
      </div></>}
      </div>
    </>
  );
}

export default CommonHeader;
