import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import {areaSize, weight, liquidated, measurementArr} from '../../../utility/Measurement';
// import { updateElement } from '../../reducers/FormBuilderSlice'; 
import PricingByComponent from './PricingByComponent'

// import {updateValue} from '../../reducers/FormBuilderSlice';

 

const AttributeElement = ({ data, index, handleChange, handleUngroupService, handeleMeasurement}) =>{
    // const [query, setQuery] = useState('');
    const [active, setActive] = useState(data.measurement?true:false); 
    const handelActive=()=>setActive(!active);
    const { attrIdentifier } = useSelector((state) => state.formBuilderData)
    // const dispatch = useDispatch()

 
    // console.table('=====================:',data)
  
  return (
    <>
    <Form.Check type="switch" id={`UngroupService_${index}`} 
        label="Ungroup Service" name={`UngroupService_${index}`}
        checked={data.ungroupService && data.ungroupService !== 'N' && true} 
        onClick={(e)=>{
                    if(e.target.checked){handleUngroupService('ungroupService', 'Y')
                        } else {
                            handleUngroupService('ungroupService', 'N')
                        }
                }} />
                        <Form.Check type="switch" id={`isPrice_${index}`} 
                            label="Allow for Pricing Configuration" name={`isPrice_${index}`}
                            checked={data.isPrice && data.isPrice !== 'N' && true} 
                            onClick={(e)=>{
                                if(e.target.checked){handleUngroupService('isPrice', 'Y')
                                    } else {
                                        handleUngroupService('isPrice', 'N')
                                    }
                            }} />
                        {data.btnName !=='textField'&&<>
                        <Form.Check type="switch" id={`defaultValue${index}`} 
                            label={`Allow size to exist or not`} name={`defaultValue${index}`}
                            checked={data.defaultValue && data.defaultValue !== 0 && true} 
                            onClick={(e)=>{
                                handelActive();
                                handeleMeasurement('measurement', e.target.checked===true?measurementArr[0].name:false);
                                if(e.target.checked){
                                    handleUngroupService('defaultValue', 1)
                                } else {
                                    handleUngroupService('defaultValue', 0)
                                }
                            }} />
                            
                            </>}
                            


                        {data.btnName ==='textField'&&<>
                        <Form.Check type="switch" id={`measurements${index}`} 
                            label={`What do you want to show measurements form?`} name={`measurements${index}`}
                            checked={active && true} 
                            onClick={(e)=> { 
                                 handeleMeasurement('measurement', e.target.checked===true?measurementArr[0].name:false);
                                 if(data.labelType !=='number'){
                                 handeleMeasurement('optionalSize', e.target.checked===true?true:false)}
                                handelActive();}} />
                            
                        </> }
                        {active && <> 
                                <div className='from-group'>
                                <label htmlFor={`measurement_${index}`} >Measurement Type </label>
                                <select name={data.pricingBy} className= "form-select" id={`measurement_${index}`}
                                    onChange={(e) =>{ 
                                        console.log( e.target.value)
                                        // handeleMeasurement('measurement', e.target.value)
                                        // if(e.target.value==='areaSize'){handeleMeasurement('measurement', areaSize)}
                                        // else if(e.target.value==='weight'){handeleMeasurement('measurement', weight)}
                                        // else if(e.target.value==='liquidated'){handeleMeasurement('measurement', liquidated)}
                                        // else{handeleMeasurement('measurement', e.target.value)}

                                        const currentData=measurementArr.filter((item) => item.name===e.target.value)
                                        handeleMeasurement('measurement', currentData) 
                                    }}>
                                    {/* <option key='' value='' selected disabled>Select Type</option> */}
                                    {measurementArr.map((item) => (<option value={item.name} selected={data.measurement && data.measurement[0].name===item.name}> {item.name}</option>))}
                    

                                    {/* <option key='areaSize'selected={data.measurement && data.measurement[0].name==='areaSize'} value={'areaSize'}>Area Size</option>
                                    <option key='weight' selected={data.measurement && data.measurement[0].name==='weight'} value={'weight'}>Weight</option>
                                    <option key='liquidated' selected={data.measurement && data.measurement[0].name==='liquidated'} value={'liquidated'}>Liquidated</option> */}
                                </select>
                                </div>
                            </>}

                        {/* <PricingByComponent data={data} index={index} handleChange={handleChange} /> */}

                         {data.btnName==='textField' && <div className='from-group'>
                            <label htmlFor={`attributeIdentifierTextId${index}`} >Attribute Identifier</label>
                            <select name={data.attributeIdentifierTextId} className= "form-select" id={`attributeIdentifierTextId${index}`}
                                onChange={(e) =>{
                                    // const currentData=identifier.filter((item) => item.name===e.target.value)
                                    handeleMeasurement('attributeIdentifierTextId', e.target.value) 
                                }}>
                                <option key='' value='' selected disabled>Select Type</option>
                                {/* {attrIdentifier.map((item) => (<option value={item.textId}> {item.title}</option>))} */}
                                {attrIdentifier.map((item) => (<option value={item.textId} selected={data.attributeIdentifierTextId && data.attributeIdentifierTextId===item.textId}> {item.title}</option>))}
                            </select>
                    
                        </div>}
                        

                        <div className='from-group'>
                            <label htmlFor={`instructionsEndUser_${index}`}>Instructions for EndUser</label>
                             <textarea className="form-control" value={data.instructionsEndUser} placeholder='Instructions for EndUser' id={`instructionsEndUser_${index}`}
                                      onChange={(e) => handleChange('instructionsEndUser', e.target.value)} rows="4"
                                      cols="50"></textarea>
                        </div>

                        <div className='from-group'>
                            <label htmlFor={`instructionsProvider_${index}`}>Instructions for Provider</label>
                            <textarea className="form-control" value={data.instructionsProvider} placeholder='Instructions for Provider' id={`instructionsProvider_${index}`}
                                      onChange={(e) => handleChange('instructionsProvider', e.target.value)} rows="4"
                                      cols="50"></textarea>
                        </div>

                       
     </>
  );
}

export default AttributeElement;
