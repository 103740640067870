import React, {useEffect, useState} from "react";
import { Form } from "react-bootstrap";
import * as moment from 'moment';
// import CityForm from "./CityForm";
// import ManageZipForm from "./ManageZipForm";
import {useDispatch, useSelector} from "react-redux";
// import { citySchema } from "../../schemas/city";
// import { userSchema } from "./schemas/city";

import {fetchInvoice } from "../../reducers/DisputeClaimSlice";

import ClaimListComponent from "../../component/disputeClaim/ClaimListComponent";
import Layout from "../../components/Layout";
// import {Link} from "react-router-dom";

import { getUniqueData } from "../../utility/FilterDataTable";
import { monthNames } from "../../utility/DateFormate";
// import ExportFile from "../../component/utility-component/ExportFile";
// import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
// import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// import DatePicker from "react-datepicker";
import { DateRangePicker } from 'react-date-range';
// import NotDataFound from "../../components/NotDataFound";
 import LoadingComponent from "../../modal/LoadingComponent"; 

const InvoiceListPage = () => {
    const dispatch = useDispatch();
    const { claimList, loading} = useSelector((state) => state.disputeClaim);
    const [filterBy, setFilterBy] = useState('');
    const [filterByProdider, setFilterByProdider] = useState(''); 
    const [startDate, setStartDate] = useState(false);
    const [endDate, setEndDate] = useState(false); 
    const [selectedDate, setSelectedDate] = useState(false);
    const [activeCalender, setActiveCalender] = useState(false); 
    const today = new Date()

    const year = today.getFullYear();
    const month = today.getMonth();  

    const day = String(today.getDate()).padStart(2, '0');  

    const monthName = monthNames[month];

    const handleSelect=(date) =>{
        setStartDate(date.selection.startDate)
        setEndDate(date.selection.endDate) 
        setSelectedDate(`${moment(new Date(date.selection.startDate)).format("DD MMM YYYY")} - ${moment(new Date(date.selection.endDate)).format("DD MMM YYYY")}`)
    }
    const selectionRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
    }
 

  
    let filteredData =claimList;
   
    if(selectedDate){ 
        filteredData= claimList.filter(item => { 
        const startDateObj = new Date(startDate + " UTC");
        const endDateObj = new Date(endDate + " UTC");
 
        const orderDateObj = new Date(item.orderDate);
 
        return orderDateObj >= startDateObj && orderDateObj <= endDateObj;
    });
    }

 
    let filteredProvider = filteredData.filter(item => item.userData['userName']?.toLowerCase().includes(filterByProdider.toLowerCase()))
    let uniqueData = filteredProvider.filter(item => item['disputeClaimStatus']?.toLowerCase().includes(filterBy.toLowerCase()))
 

    // const uniqueData = tableData2;
  
    // const uniqueData = order

    useEffect(() => {
        const res= dispatch(fetchInvoice());
        res.then((results) => {

        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
        setStartDate(`${year}-${monthName}-${day}`)
        setEndDate(`${year}-${monthName}-${day}`)
        // setSelectedDate((` ${day} ${monthName} ${year}`))
        // dispatch(breadcrumbTree({ 'Order Management': ''}));
    }, [dispatch, day, monthName,year]);




 
 

// Remove duplicates

    return (
        <Layout 
            page_title={'Dispute and Claim Management '} 
            breadcrumb={{'Dispute and Claim Management':''}} 
            component={
                <>
                    {loading ? <LoadingComponent message={''}/>:<ClaimListComponent data={uniqueData} />}
                </>
            } 
            top_content={
                <div className="search-header-area">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                
                    <div
                         className="w-[350px] ">
                            <div className="w-full px-[15px] py-[10px] m-[10px] bg-white rounded-[6px] border border-gray-200 justify-between items-center inline-flex cursor-pointer" onClick={()=>setActiveCalender(true)}>
                                <div className="">{selectedDate || activeCalender?selectedDate:` ${day} ${monthName} ${year}`} </div>
                                <div className="w-4 h-4 ml-[1px] relative"><i className="fa fa-calendar" aria-hidden="true"></i></div>
                            </div>
                         {activeCalender &&
        
                        <div className='relative date-wrapper'>
                            <div className='offset-layer ' onClick={()=> setActiveCalender(false)}></div>
                        <DateRangePicker  
                                         ranges={[selectionRange]}
                                         onChange={handleSelect}/>
                        </div>
                    }
                    </div>
                   

                    <Form.Select aria-label="Filter By Provider" className="search-type" name="searchType"
                                 value={filterByProdider} onChange={(e) => {
                        setFilterByProdider(e.target.value);
                    }}>
                        <option value=''>Filter By Provider</option>
                        {Object.values(getUniqueData('serviceTitle', filteredData)).map((key, index) => (<>{key &&
                            <option key={`${key}_${index}`} value={key}>{key}</option>}</>))}

                    </Form.Select>
                    <Form.Select aria-label="Filter By status" className="search-type" name="searchType"
                                 value={filterBy} onChange={(e) => {
                                    setFilterBy(e.target.value); 
                    }}>
                        <option value=''>Filter By status</option>
                        {Object.values(getUniqueData('disputeClaimStatus', filteredData)).map((key, index) => (<>{key &&
                            <option key={`${key}_${index}`} value={key}>{key}</option>}</>))} 
                    </Form.Select>
                </div>
          
            }
        ></Layout>
    );
};

export default InvoiceListPage;
