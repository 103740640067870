
import { useEffect, useState } from "react";
import { userLogRemove } from '../reducers/AuthSlice';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

// import {  removeFCMTokenPost } from '../reducers/AuthSlice';
import { removeFCMToken} from '../utility/FcmTokenSaveVsRemove';

const Logout = () => {
const navigate = useNavigate()
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth)
  // navigate('/');
console.log('user.res:'+user.res)
  if(user.res === true) {
 
    //  setTimeout(()=> window.location.replace('/login'),1000);
  } 

// localStorage.removeItem("textId")
// localStorage.removeItem("token")
 
const handleRemoveLog = async () => {
 
    const res = dispatch(userLogRemove(removeFCMToken()));
        res.then((results)=> {
          // localStorage.removeItem("fcmToken");
                localStorage.removeItem("textId")
                localStorage.removeItem("token")
                
                setTimeout(() => navigate('/login'), 1000);

          // if(results.error){
          //   console.log('results', results.error)
          // }else{
          //   const res= dispatch(removeFCMTokenPost(removeFCMToken()));
          //   res.then(()=>{
          //       localStorage.removeItem("fcmToken");
          //       localStorage.removeItem("textId")
          //       localStorage.removeItem("token")
          //       setTimeout(() => navigate('/login'), 1000);
          //       })
          
          // }
                
                
    }).catch(error => {
        console.error('Error:', error);
    });
  
};


  useEffect(() => {
    handleRemoveLog()
    // const res=dispatch(userLogRemove({'textId': textId,'token':token}));
    // res.then((results)=> {
    //             //  setTimeout(()=> navigate('/login'),1000);
    //             console.log('results', results)
    //         }).catch(error => {
    //             console.error('Error:', error);
    //         });
  }, [dispatch]);

  return (
 <></>
  );
};



export default Logout;