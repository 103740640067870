import React, { useState } from 'react';
import {useEffect} from "react";
import './../../assets/css/dispute-claim.css';
import Layout from '../../components/Layout';
import EmptyState from '../../component/disputeClaim/EmptyState';
import ReasonListComponent from '../../component/disputeClaim/ReasonListComponent';
import ResolutionTypeComponent from '../../component/disputeClaim/ResolutionTypeComponent';
import DisputeStatusComponent from '../../component/disputeClaim/DisputeStatusComponent';

import { fetchClaimReason } from "../../reducers/DisputeClaimSlice";
import {useDispatch, useSelector} from "react-redux";
import LoadingComponent from '../../modal/LoadingComponent';
const DisputeSetting = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Dispute Reason');
  const [clickedNew, setClickedNew] = useState(false);
  const { claimList, reasonList, resolutionType, statusList, loading } = useSelector((state) => state.disputeClaim);

  const tabs = [
    'Dispute Reason',
    'Resolution Type',
    'Dispute Timeline Status',
  ];


  const renderTabContent = () => {
    if (loading) {
      return <div className="loading-state">Loading...</div>;
    }

    switch (activeTab) {
      case 'Dispute Reason':
        return reasonList && reasonList.length > 0 ? (
          <ReasonListComponent data={reasonList} />
        ) : clickedNew ? (
          <ReasonListComponent data={claimList} />
        ) : (
          <EmptyState
            title="No Dispute Reasons Added Yet"
            description="Get started by adding a new dispute reason."
            buttonLabel="Add New Reason"
            onButtonClick={() => setClickedNew(true)}
          />
        );
      case 'Resolution Type':
        return resolutionType && resolutionType.length > 0 ? (
          <ResolutionTypeComponent data={resolutionType} />
        ) : clickedNew ? (
          <ResolutionTypeComponent data={claimList} />
        ) : (
          <EmptyState
            title="No Resolution Types Added Yet"
            description="Start by adding a new resolution type."
            buttonLabel="Add New Type"
            onButtonClick={() => setClickedNew(true)}
          />
        );
      case 'Dispute Timeline Status':
        return statusList && statusList.length > 0 ? (
          <DisputeStatusComponent data={statusList} />
        ) : clickedNew ? (
          <DisputeStatusComponent data={claimList} />
        ) : (
          <EmptyState
            title="No Timeline Status Added Yet"
            description="Add a new timeline status to get started."
            buttonLabel="Add New Status"
            onButtonClick={() => setClickedNew(true)}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(fetchClaimReason());
  }, [dispatch,activeTab]);

  return (
    <Layout 
    page_title={'Dispute and Claim Management '} 
    breadcrumb={{'Dispute and Claim Management':''}} 
    component={
        <>
        {loading ? <LoadingComponent message={''}/>: 
                
    <div className="tab-system">
      {/* Tab Header */}
      <div className="tab-header">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`tab-item ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>}
    </>
            }
        ></Layout>
    );
};

 


export default DisputeSetting;
