import { Form } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { updateRolePermission, createRolePermission } from "../../../reducers/IdentitySlice";
import {moduleRolePermissionSchema} from "../../../schemas/faq";
const AddRolePermissionComponent = ({ data, close, module, role }) => {
  const dispatch = useDispatch();
  const [exitError, setExitError] = useState(null);

  const formik = useFormik({
    initialValues: {
      roleTextId: data.roleTextId || "",
      moduleTextIdData: module.map((item) => ({
        moduleTextId: item.textId,
        created: "N",
        read: "N",
        update: "N",
        delete: "N",
      })),
    },
    onSubmit: (values) => {
    console.log(values); // Logs the structured JSON format
    // Dispatch the structured data
    const res = dispatch(createRolePermission(values));
    res.then(results => {
     
      if(results.error) {setExitError('Something went wrong. Please try again!'); 

      }
      else {
      close(results.payload.results); 
      }
    }).catch(error => {
        alert('error ---- false')
    });
  },

  validationSchema: moduleRolePermissionSchema,

    // onSubmit: (values, action) => {

    //     const res = dispatch(createRolePermission(values));
    //     res.then(results => {
    //         console.log(res)
    //         if(results.error) {setExitError('Something went wrong. Please try again!'); 

    //         }
    //         else {
    //         close(results.payload.results); 
    //         }
    //     }).catch(error => {
    //         alert('error ---- false')
    //     });
    //     console.log(res)
    // },
    // onSubmit: async (values) => {

    //   try {
    //     const res = await dispatch(
    //       data.update === true ? updateRolePermission(values) : createRolePermission(values)
    //     );

    //     if (res.error) {
    //       setExitError("Something went wrong. Please try again!");
    //     } else {
    //       close(res.payload.results);
    //     }
    //   } catch (error) {
    //     setExitError("An unexpected error occurred.");
    //   }
    // },
  }
);

  const { values, handleSubmit, setValues, errors } = formik;

  const handlePermissionChange = (moduleTextId, permissionType, isChecked) => {
    const updatedModules = values.moduleTextIdData.map((module) =>
      module.moduleTextId === moduleTextId
        ? {
            ...module,
            [permissionType]: isChecked ? "Y" : "N",
          }
        : module
    );

    setValues({
      ...values,
      moduleTextIdData: updatedModules,
    });
  };

  const handleMasterCheckboxChange = (moduleTextId, isChecked) => {
    // Update all permissions for the selected module
    const updatedModules = values.moduleTextIdData.map((module) =>
      module.moduleTextId === moduleTextId
        ? {
            ...module,
            created: isChecked ? "Y" : "N",
            read: isChecked ? "Y" : "N",
            update: isChecked ? "Y" : "N",
            delete: isChecked ? "Y" : "N",
          }
        : module
    );

    setValues({
      ...values,
      moduleTextIdData: updatedModules,
    });
  };
  console.log(values)

  return (
    <div className="wrap-content">
      <Form onSubmit={handleSubmit} className="form-data">
        <div className="section-two-column faq-form">
          {exitError && <div className="alert alert-danger">{exitError}</div>}

          {/* Worker Role Selection */}
          <div className="field-row">
            <label>Worker Role</label>
            <select
              className="form-select"
              name="roleTextId"
              value={values.roleTextId}
              onChange={(e) =>
                setValues({ ...values, roleTextId: e.target.value })
              }
            >
              <option value="">Select</option>
              {role.map((item) => (
                <option key={item.textId} value={item.textId}>
                  {item.title}
                </option>
              ))}
            </select>
            {errors.roleTextId && <p className="red">{errors.roleTextId}</p>} 
          </div>

          {/* Permissions Table */}
          <table className="permissions-table">
            <thead>
              <tr>
                <th>Module Name</th> 
                <th>Read</th>
                <th>Create</th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {module.map((item) => (
                <tr key={item.textId}>
                    <td> 
                     <input
                      type="checkbox"
                      id={`${item.textId}_master`}
                      name={`${item.textId}_master`}
                      checked={
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.read === "Y" &&
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.created === "Y" &&
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.update === "Y" &&
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.delete === "Y"
                      }
                      onChange={(e) =>
                        handleMasterCheckboxChange(item.textId, e.target.checked)
                      }
                    />&nbsp;    <label htmlFor={`${item.textId}_master`} >{item.title}</label> </td>

         

                  {/* Individual Checkboxes */}
                  <td>
                    <input
                      type="checkbox"
                      id={`${item.textId}_read`}
                      name={`${item.textId}_read`}
                      onChange={(e) =>
                        handlePermissionChange(item.textId, "read", e.target.checked)
                      }
                      checked={
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.read === "Y"
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      id={`${item.textId}_create`}
                      name={`${item.textId}_create`}
                      onChange={(e) =>
                        handlePermissionChange(item.textId, "created", e.target.checked)
                      }
                      checked={
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.created === "Y"
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      id={`${item.textId}_update`}
                      name={`${item.textId}_update`}
                      onChange={(e) =>
                        handlePermissionChange(item.textId, "update", e.target.checked)
                      }
                      checked={
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.update === "Y"
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      id={`${item.textId}_delete`}
                      name={`${item.textId}_delete`}
                      onChange={(e) =>
                        handlePermissionChange(item.textId, "delete", e.target.checked)
                      }
                      checked={
                        values.moduleTextIdData.find(
                          (module) => module.moduleTextId === item.textId
                        )?.delete === "Y"
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Save Buttons */}
          <div className="save-option">
            <button type="submit" className="save-btn">
              {data.update === true ? "Update" : "Save"}
            </button>
            <button
              type="button"
              className="save-btn"
              onClick={() => close()}
            >
              Close
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddRolePermissionComponent;
