import {  useSelector } from "react-redux";

function copyClipboard(copyText) {
  navigator.clipboard.writeText(copyText);
}
const DynamicVariable = () => {
 

  const { variable_array} = useSelector((state) => state.notification);
     
 
  return (
    <fieldset className="clipboard">
        <legend>Dynamic Variable:</legend>
        {variable_array.map((item, index) => (
            <div className="btn variable" key={index} onClick={()=>copyClipboard(item)}>
                {item}
            </div>
        ))}
    </fieldset>
  );
};

export default DynamicVariable;
