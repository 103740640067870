import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    unauthorized: true,
    loading: false,
    claimList: [],
    reasonList:[], 
    resolutionType:[], 
    statusList:[],
    error: ''
}

const url = `${BASE_URL}/support`

const token = localStorage.getItem("token")
// const textId = localStorage.getItem("textId")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}

export const fetchInvoice = createAsyncThunk(
    'fetch/getInvoiceData', async () => {
        return axios
        .get(`${url}/dispute-claim-list/`, config)
        .then((response) => response.data)
    }
)

export const fetchClaimReason = createAsyncThunk(
    'fetch/fetchClaimReason', async () => {
        return axios
        .get(`${url}/dispute-reason/`, config)
        .then((response) => response.data)
    }
)

// export const fetchClaimResolution = createAsyncThunk(
//     'fetch/fetchClaimResolution', async () => {
//         return axios
//         .get(`${url}/dispute-resolution/`, config)
//         .then((response) => response.data)
//     }
// )
// export const fetchClaimStatus = createAsyncThunk(
//     'fetch/fetchClaimStatus', async () => {
//         return axios
//         .get(`${url}/dispute-status/`, config)
//         .then((response) => response.data)
//     }
// )

export const createClaimReason = createAsyncThunk(
    "post/createClaimReason", async (formData) => { 
        return axios
            .post(`${url}/dispute-reason/`, formData, config)
            .then((response) => response.data
            )
    }
)
export const updateClaimReason = createAsyncThunk(
  "put/updateClaimReason", async (formData) => { 
    console.log(formData)
    return axios
    .put(`${url}/dispute-reason/?textId=${formData.textId}`, formData, config)
    .then((response) => response.data
    )
  }
) 
export const deleteClaimReason = createAsyncThunk(
  "delete/deleteClaimReason", async (formData) => { 
    return axios
    .delete(`${url}/dispute-reason/?textId=${formData.textId}&localIndex=${formData.localIndex}`, config)
    .then((response) => response.data
    )
  }
) 
export const updateClaimResolution = createAsyncThunk(
  "put/updateClaimResolution", async (formData) => { 
    console.log(formData)
    return axios
    .put(`${url}/dispute-resolution/?textId=${formData.textId}`, formData, config)
    .then((response) => response.data
    )
  }
) 
export const deleteClaimResolution = createAsyncThunk(
  "delete/deleteClaimResolution", async (formData) => { 
    return axios
    .delete(`${url}/dispute-resolution/?textId=${formData.textId}&localIndex=${formData.localIndex}`, config)
    .then((response) => response.data
    )
  }
) 

export const createClaimResolution = createAsyncThunk(
    "post/createClaimResolution", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/dispute-resolution/`, formData, config)
            .then((response) => response.data
            )
    }
)
// export const createClaimStatus = createAsyncThunk(
//     "post/createClaimStatus", async (formData) => {
       
//         // console.log(formData)
//         return axios
//             .post(`${url}/dispute-status/`, formData, config)
//             .then((response) => response.data
//             )
//     }
// )
  
const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
 
    },
    extraReducers: (builder) => {
    
      builder.addCase(fetchInvoice.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchInvoice.fulfilled, (state, action) => {
        state.loading = false; 
        state.claimList = action.payload.dispute_list;
        state.error = "";
      });
      builder.addCase(fetchInvoice.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });
      

      builder.addCase(fetchClaimReason.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchClaimReason.fulfilled, (state, action) => {
        state.loading = false; 
        state.reasonList = action.payload.disputeReason;
        state.resolutionType = action.payload.disputeResolution;
        state.statusList = action.payload.disputeStatus;
        state.error = "";
      });
      builder.addCase(fetchClaimReason.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });

      builder.addCase(updateClaimReason.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateClaimReason.fulfilled, (state, action) => {
        state.loading = false; 
        state.reasonList[action.payload.localIndex] = action.payload.created_data;
        state.error = "";
      });
      builder.addCase(updateClaimReason.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });

      builder.addCase(deleteClaimReason.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteClaimReason.fulfilled, (state, action) => {
        state.loading = false; 
        state.reasonList.splice(action.payload.localIndex, 1);
        state.error = "";
      });
      builder.addCase(deleteClaimReason.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });

      builder.addCase(updateClaimResolution.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateClaimResolution.fulfilled, (state, action) => {
        state.loading = false; 
        state.resolutionType[action.payload.localIndex] = action.payload.created_data;
        state.error = "";
      });
      builder.addCase(updateClaimResolution.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });

      builder.addCase(deleteClaimResolution.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteClaimResolution.fulfilled, (state, action) => {
        state.loading = false; 
        state.resolutionType.splice(action.payload.localIndex, 1);
        state.error = "";
      });
      builder.addCase(deleteClaimResolution.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });

      // builder.addCase(fetchClaimResolution.pending, (state) => {
      //   state.loading = true;
      // });
      // builder.addCase(fetchClaimResolution.fulfilled, (state, action) => {
      //   state.loading = false; 
      //   state.resolutionType = action.payload.disputeResolution;
      //   state.error = "";
      // });
      // builder.addCase(fetchClaimResolution.rejected, (state, action) => {
      //   state.loading = false; 
      //   state.error = action.error;
      // });
       

      // builder.addCase(fetchClaimStatus.pending, (state) => {
      //   state.loading = true;
      // });
      // builder.addCase(fetchClaimStatus.fulfilled, (state, action) => {
      //   state.loading = false; 
      //   state.statusList = action.payload.disputeClaims;
      //   state.error = "";
      // });
      // builder.addCase(fetchClaimStatus.rejected, (state, action) => {
      //   state.loading = false; 
      //   state.error = action.error;
      // });

      builder.addCase(createClaimReason.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createClaimReason.fulfilled, (state, action) => {
        state.loading = false; 
        // state.ReasonList = action.payload.created_data;
        state.reasonList.push(action.payload.created_data);
        state.error = "";
 
      });
      builder.addCase(createClaimReason.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });
       
       

      builder.addCase(createClaimResolution.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createClaimResolution.fulfilled, (state, action) => {
        state.loading = false;  
        state.resolutionType.push(action.payload.created_data);
        state.error = "";
 
      });
      builder.addCase(createClaimResolution.rejected, (state, action) => {
        state.loading = false; 
        state.error = action.error;
      });
       
       

      // builder.addCase(createClaimStatus.pending, (state) => {
      //   state.loading = true;
      // });
      // builder.addCase(createClaimStatus.fulfilled, (state, action) => {
      //   state.loading = false; 
      //   // state.ReasonList = action.payload.created_data;
      //   state.statusList.push(action.payload.created_data);
      //   state.error = "";
 
      // });
      // builder.addCase(createClaimStatus.rejected, (state, action) => {
      //   state.loading = false; 
      //   state.error = action.error;
      // });
       
    },
  });
  
export const { updateBellCount } = notificationSlice.actions;

export default notificationSlice.reducer

 
