import {Form} from "react-bootstrap";
// import { Modal, Button, Image} from "react-bootstrap";
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
// import zone from "../../data/zone";
// import {BASE_URL} from "../../BaseUrl";
// import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch } from 'react-redux'
import React from "react";
// import { useSelector} from 'react-redux'
// import  {useEffect, useState} from "react";
// import {CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {notificationTemplateSchema} from "../../schemas/category";
import {useFormik} from "formik";
// import {createCategory, successConfirmation, updateCategory} from '../../reducers/CategorySlice'
// import {fetchServices} from "../../reducers/ServiceItemSlice";
import { addNotificationTemplatePost, updateNotificationTemplatePut} from "../../reducers/NotificationSlice";
import { ToastContainer, toast } from 'react-toastify';
import '../../assets/tostify/ReactToastify.css';
import DynamicVariable from "./DynamicVariable";

// import LoadingComponent from "../../modal/LoadingComponent";
// import ErrorComponent from "../../modal/ErrorComponent";
// import SuccessComponent from "../../modal/SuccessComponent";
// const userType=[
//                 {name:'ADMIN',vakue:'Admin'}, 
//                 {name:'ENDUSER',vakue:'End User'}, 
//                 {name:'PROVIDER',vakue:'Service Provider'}, ]

const AddNewTemplateForNotification = ({data, categories, close}) => {

    // const [breadcrumb, setBreadcrumb] = useState(false);
    // const [sender, setSender] = useState(false);
    // const [recever, setRecever] = useState(false);
    // const [itemAddStatus, setItemAddStatus] = useState(false);
 
    const dispatch = useDispatch();
    // const [editorData, setEditorData] = useState('');

    // const {item, category} = useParams();
    // const navigate = useNavigate(); 
 

    const {values, errors, handleChange, handleSubmit, setValues} = useFormik({
        initialValues: data,
        onSubmit: (values, action) => {
            if(values.update){
                const res = dispatch(updateNotificationTemplatePut(values));
            res.then(results => {
                // console.log('--------- update ---------:',results)
               if(results.error){
                // console.log('------- update error -----------:',results)
                toast(` Your action was successful! `);
                
               } else {
               close();}
            }).catch(error => {
                alert('error ---- false')
            });

            }else {

            const res = dispatch(addNotificationTemplatePost(values));
            // console.log('--------- add res ---------:',res)
            res.then(results => {
            //    console.log('--------- add ---------:',results)
               if(results.error){
                // console.log('-------add  error -----------:',results.payload.message)
                toast(` ${results.payload.message} `);
                
               } else {
               close();}
            }).catch(error => {
                alert('error ---- false')
            });
        }

        },
         validationSchema: notificationTemplateSchema,

    });
    // console.log("🚀 values:", values)

     
    return (
        <>
        <DynamicVariable />
        <div className="wrap-content"> 
            <Form onSubmit={handleSubmit} className="form-data">
                
                <div className="section-two-column  notification-form">
                            <div className="field-row"><label className="label">Event Name </label>
                             
                            <input name="title" placeholder="Event Name" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.title} onChange={handleChange} />
                             {errors.title && <p className="red">{errors.title}</p>}   
                            </div>
{/* 
                        <div className="field-row">
                            <label> Notification Type  </label>
                            <select className={`form-select`} name="notificationType" defaultValue={values.notificationType}
                             onChange={(e)=>{
                                setSender(e.target.value)
                                setValues({...values, notificationTo: e.target.value})
                                return handleChange;
                            }}>
                                <option value=''> select  </option>
                                <option value={`CUSTOM`} disabled={recever==='CUSTOM'?true:false}> Custom</option>
                                <option value={`ORDER`} disabled={recever==='ORDER'?true:false}> Order</option>
                                <option value={`CUSTOMER`} disabled={recever==='CUSTOMER'?true:false}> Customer </option>
                                <option value={`PROVIDER`} disabled={recever==='PROVIDER'?true:false}> Provider </option>
                                  
                            </select> 
                        </div> */}
                        
                        {/* <div className="field-row">
                            <label> Notification To  </label>
                            <select className={`form-select`} name="notificationTo" defaultValue={values.notificationTo}
                             onChange={(e)=>{
                                setSender(e.target.value)
                                setValues({...values, notificationTo: e.target.value})
                                return handleChange;
                            }}>
                                <option value=''> select  </option>
                                <option value={`ADMIN`} disabled={recever==='ADMIN'?true:false}> Admin</option>
                                <option value={`ENDUSER`} disabled={recever==='ENDUSER'?true:false}> End User</option>
                                <option value={`PROVIDER`} disabled={recever==='PROVIDER'?true:false}> Service Provider</option>
                                  
                            </select> 
                        </div> */}

                        {/* <div className="field-row">
                            <label> Notification From </label>
                            <select className={`form-select`} name="notificationFrom" defaultValue={values.notificationFrom}
                            onChange={(e)=>{
                                setRecever(e.target.value)
                                setValues({...values, notificationFrom: e.target.value})
                                return handleChange;
                            }}>
                                <option value=''> select  </option>
                                <option value={`ADMIN`} disabled={sender==='ADMIN'?true:false}> Admin</option>
                                <option value={`ENDUSER`} disabled={sender==='ENDUSER'?true:false}> End User</option>
                                <option value={`PROVIDER`} disabled={sender==='PROVIDER'?true:false}> Service Provider</option>
                                  
                            </select> 
                        </div> */}

                        


                        <div className="field-row">
                            <label className="label">Event Label</label>
                            <input name="notificationEventLabel" placeholder="Event Label" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.notificationEventLabel} onChange={handleChange} />
                            {/*<CKEditor editor={ClassicEditor} details={editorData} onChange={handleChangea}  />*/}
                            {errors.notificationEventLabel && <p className="red">{errors.notificationEventLabel}</p>}  
                        </div>

                        <div className="field-row"><label className="label">Event Content</label>
                            <textarea className="form-control description" name="notificationContent"
                                      value={values.notificationContent} onChange={handleChange} rows="4" placeholder="Event Content"
                                      cols="50"></textarea>
                            {/*<CKEditor editor={ClassicEditor} details={editorData} onChange={handleChangea}  />*/}
                            {errors.notificationContent && <p className="red">{errors.notificationContent}</p>}  
                        </div>
                        <div className="field-row">
                            <label> Notification Category</label>
                            <select className={`form-select`} name="categoryTextId" defaultValue={values.categoryTextId} onChange={handleChange}>
                                <option value=''> select  </option>
                                {categories.map((item) => (
                                    <option value={item.textId}> {item.title}</option>))}
                            </select> 
                     
                            {errors.categoryTextId && <p className="red">{errors.categoryTextId}</p>}  
                        </div>
            
                        <div className='field-row'> 
                        <Form.Check type="switch" id="status" label="Status" name="status" defaultChecked={values.status === 'Y'? true:false} value={values.status} 
                        onChange={(e)=>{
                            setValues({...values,status:e.target.checked?'Y':'N'});
                            }}/>
                       
                        </div>
             


                    <div className="save-option">
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft  </button> */}
                        {/* <button className="save-btn">Save</button> */}
                        <button className="save-btn">{data.update===true?'Updated':'Save'}</button>
                        <button className="save-btn" onClick={()=>close()}>Close</button>
                    </div>

                </div>
            </Form>
        </div>
        </> 
    );
};

export default AddNewTemplateForNotification;
