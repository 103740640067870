import React from 'react'
// import {BASE_URL} from "../../BaseUrl";
const BundleServiceAttribute = ({data}) => {

    console.log(data)
    return (
        <div className="bundle-service">
            <div className="bundle-header">
                <h2>Bundle Service</h2>
                <span className="package-type">Fixed Package</span>
            </div>
            <div className="bundle-card">
                <div className="bundle-title">
                    <h3>Bundle</h3>
                    <span className="check-mark"><img src='/images/double-tick.svg' alt='' /></span>
                </div>
                <div className="options">
                    {/* {data && data.map((item, index) => (
                        <div className='options-item' key={item.title.replace(" ", "_")}><img className="icon" src='/images/square-ring.png' alt='' /> {item.title}: <strong>{item.options}</strong></div>
                        ))}  */}
                        {data?.map((item, index) => (
                            <div className='options-item' key={item.title.replace(" ", "_")}>
                                <img className="icon" src='/images/square-ring.png' alt='' />
                                {item.title}:&nbsp;
                                <strong>
                                {Array.isArray(item.options) 
                                    ? item.options.map(option => option.title).join(", ") 
                                    : item.options}
                                </strong>
                            </div>
                            ))}
                </div>
            </div>
        </div>

    );
};

export default BundleServiceAttribute;