import React, { useState} from "react";
// import {Form, Modal, Button, Image} from "react-bootstrap";
import * as moment from 'moment';
// import CityForm from "./CityForm";
// import ManageZipForm from "./ManageZipForm";
// import {useDispatch, useSelector} from "react-redux";
// import { citySchema } from "../../schemas/city";
// import { userSchema } from "./schemas/city";

// import {fetchOrder, fetchOrderTracking} from "../../reducers/OrderSlice";
import { monthNames } from "../../utility/DateFormate";
import CustomDataTable from "../../components/CustomDataTable";
// import Layout from "../../components/Layout";
import {Link} from "react-router-dom";
// 
import {filterDataTable} from "../../utility/FilterDataTable";
import ExportFile from "../../component/utility-component/ExportFile";
// import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
// import { Calendar } from 'react-date-range';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
// import DatePicker from "react-datepicker";
// import { DateRangePicker } from 'react-date-range';
// import NotDataFound from "../../components/NotDataFound";

const OrderListComponent = ({data}) => {
    const [query, setQuery] = useState('');
 
    let tableData2 = data.filter(item => item['textId']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = data.filter(item => item['userName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData1 = data.filter(item => item['serviceName']?.toLowerCase().includes(query.toLowerCase()))
 
    // const uniqueData = tableData2
    const uniqueData = filterDataTable(tableData1, tableData2, tableData3);
    
    const columns = [
        // { name: "ID", sortable: true, cell: (row) => row.id },
        {name: "Order Id", minWidth: '200px', sortable: false, selector: (row) => row.textId},
        {name: "Date", sortable: true, selector: (row) => moment(new Date(row.orderDate)).format("DD MMM YYYY,  h:mm A ")},
        {name: "Service", sortable: false, selector: (row) => row.serviceName},
        {name: "Customer", sortable: false, selector: (row) => row.userName},
        {name: "Provider", sortable: false, selector: (row) => row.workerName},
        {name: "Price", sortable: true, selector: (row) => `$${row.amount}`},
        // { name: "Completed Time", sortable: true, cell: (row) => row.completedTime },
        {name: "Status", sortable: true, cell: (row) => <span className={`status ${row.status}`}> {row.status}</span>},
        {
            name: "Details", cell: (row) => <Link to={`./${row.textId}`}
                                                  className="btn btn-default"> Details</Link>
        },
    ];

 
 
 

    return  (<>
         

 
        <div className='data-table-wrapper order-table'>
            <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                    <input name="search" placeholder={'Search by Services Title or customer...'}
                            type="text"
                            className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                            value={query ? query : ''}
                            onChange={(e) => {
                                setQuery(e.target.value)
                            }}/>
                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                    <div
                        className="export-btn absolute right-0 top">
                        <ExportFile data={uniqueData} fileName={'Order-Table-data'}/>
                    </div>
                </div>

            </div>
            <CustomDataTable data={uniqueData} columns={columns}/>
        </div>
        
     
 
</>)
}
export default OrderListComponent;
