
export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export function getDateFromText(dateTimeString){
        // Create a new Date object from the string
        const dateObject = new Date(dateTimeString);
        // Extract year, month (0-indexed), and day
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth(); // Adjust for 0-based indexing
        const day = dateObject.getDate();

        //  "2024-10-24T14:36:19Z" to 10/24/2024
        const formattedDate = `${month + 1}/${day}/${year}`;  

        return formattedDate;

}


export function getDateString (dateString) {
    const date = new Date(dateString);

    // Use Intl.DateTimeFormat for formatting
    const dayName = new Intl.DateTimeFormat('en-GB', { weekday: 'short' }).format(date); // Fri
    const day = new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(date); // 15
    const monthName = new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(date); // March
    const year = new Intl.DateTimeFormat('en-GB', { year: 'numeric' }).format(date); // 2024
    const time = new Intl.DateTimeFormat('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    }).format(date); // 12:02 PM

    return `${dayName} ${day} ${monthName} ${year}, ${time}`;
    // out put :::: "2024-10-24T14:36:19Z" to Thu 24 October 2024, 08:36 pm
};